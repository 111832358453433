import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { KeyMap } from "crewtimer-common";
import {
  N_RACE_TYPE,
  N_RACE_TYPE_SPRINT,
  N_DAY,
  N_EVENTNUM,
  N_START,
  N_EVENT_NAME,
  N_BOW,
  N_CREW_ABBREV,
  N_CREW,
  N_STROKE,
  N_GATE_ADJ,
} from "../shared/Names";
import {
  useEventResults,
  useEventSummaryList,
  useRegattaInfo,
} from "../shared/UseResults";
import { getTitle } from "../shared/StringUtil";
import { useTranslation } from "react-i18next";
import EventFilter, {
  useCrewFilter,
  useDayFilter,
  useEventFilter,
  useFilterActive,
  useFilteredEventNums,
  useStrokeFilter,
} from "../shared/EventFilter";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: "auto",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHeaderCell = styled(TableCell)((/* { theme } */) => ({
  whiteSpace: "normal",
  wordWrap: "break-word",
  fontSize: "1em",
  fontWeight: "bold",
  // height: "30px",
  color: "black",
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
}));

const StyledTableCell = styled(TableCell)((/* { theme } */) => ({
  whiteSpace: "normal",
  wordWrap: "break-word",
  fontSize: "0.9em",
  // height: "30px",
  color: "black",
  paddingLeft: "0.5em",
  paddingRight: "0.5em",
}));

const styles: { [key: string]: React.CSSProperties } = {
  EventNum: { textAlign: "center" },
  NumEntries: { textAlign: "center" },
  Bracket: { textAlign: "center" },
};

const HeaderText = {
  EventNum: "Event Num",
};

interface HeatSheetProps {
  crewFilter: string;
  strokeFilter: string;
  fullname: boolean;
  showStroke: boolean;
  showAllProp: boolean;
  filteredEventNums: string[];
  singlePagePerClub?: boolean;
  avoidBreak?: boolean;
}

const HeatSheetDetail: React.FC<HeatSheetProps> = ({
  crewFilter,
  strokeFilter,
  fullname,
  showStroke,
  showAllProp,
  filteredEventNums,
  singlePagePerClub,
  avoidBreak,
}) => {
  const [regattaData] = useEventResults();
  const [, setEventFilter] = useEventFilter();
  const [filterActive] = useFilterActive();
  const [regattaInfo] = useRegattaInfo();
  const columnTitles = regattaInfo?.Titles || {};
  const { t } = useTranslation();

  const crewFilterLower = crewFilter.toLowerCase();
  const strokeFilterLower = strokeFilter.toLowerCase();
  useEffect(() => {
    setEventFilter("");
  });
  const simplify = Boolean(!showAllProp && (crewFilter || strokeFilter));
  const crewAbbrevs: KeyMap<string> = {};
  const colsUsed = new Set<string>();
  const heatsheet: KeyMap<string>[] = [];

  let hasSprint = false;
  let hasHead = false;
  let noDays = true;
  let noStarts = true;
  regattaData.forEach((event) => {
    if (!filteredEventNums.includes(event.EventNum)) {
      return;
    }
    if (event.RaceType === "Info" || !event.entries) {
      return;
    }
    let isSprint = event[N_RACE_TYPE] === N_RACE_TYPE_SPRINT;
    hasSprint = hasSprint || isSprint;
    hasHead = hasHead || !isSprint;
    noDays = noDays && event.Day === "";
    noStarts = noStarts && event.Start === "";

    // Entries comes in sorted by display order due to other display optimizations.
    // However, we do not wish that for a heatsheet.  Rearrange the entries to original order.
    const entries: KeyMap[] = [...event.entries].sort(
      (a, b) => a.Index - b.Index
    );

    const eventInit: KeyMap = {};
    [N_DAY, N_START, N_EVENTNUM, N_EVENT_NAME].forEach((name) => {
      if (name === N_EVENT_NAME) {
        eventInit[name] = event[name] = event[name].startsWith(event.EventNum)
          ? event[name].substring(event.EventNum.length)
          : event[name];
      } else {
        eventInit[name] = event[name];
      }
    });
    entries.forEach((entry) => {
      // If we have numbers > 10, format as a head race.  Canoe/Kayak often have 10 lanes
      if (Number(entry[N_BOW] > 10 || isNaN(entry[N_BOW]))) {
        isSprint = false; // treat as head race
      }
      const abbrev = entry[N_CREW_ABBREV];
      const crew = entry[N_CREW].replace(/ [A-Z]$/, "");
      if (!abbrev.startsWith(crew)) {
        crewAbbrevs[abbrev] = crew; // remove seed 'A', 'B' etc from crew name
      }
    });

    if (isSprint) {
      const newEvent = { ...eventInit };
      entries.forEach((entry) => {
        const bow = entry[N_BOW];
        const lane = `Lane ${entry[N_BOW]}`;
        colsUsed.add(lane);
        let seed = entry[N_CREW].replace(/^.*( [A-Z])$/, "$1");
        if (seed === entry[N_CREW]) {
          seed = ""; // none found
        }
        // for fullname, add space to ; or / if not already present to allow
        // word wrapping to be effective when printing
        const crew = fullname
          ? entry[N_CREW].replace(/[;|/](?=\S)/g, (match) => `${match} `)
          : `${entry[N_CREW_ABBREV]}`;
        const stroke = showStroke ? ` ${entry[N_STROKE]}` : "";
        const info = `${entry[N_GATE_ADJ] ? `(${entry[N_GATE_ADJ]})` : ""}`;
        if (bow === "4B") {
          console.log(JSON.stringify(entry));
        }
        let hide = false;
        hide = Boolean(
          simplify &&
            crewFilter &&
            !entry[N_CREW].toLowerCase().includes(crewFilterLower)
        );
        hide ||= Boolean(
          simplify &&
            strokeFilter &&
            !entry[N_STROKE].toLowerCase().includes(strokeFilterLower)
        );
        const crewText = (crewFilter || strokeFilter) && simplify ? "" : crew;
        newEvent[lane] = hide ? "" : `${bow} ${crewText}${stroke}${info}`;
      });
      heatsheet.push(newEvent);
    } else {
      // A head race.  Collect up to 8 entries per line
      let laneNo = 1;
      let newEvent = { ...eventInit };
      entries.forEach((entry) => {
        if (laneNo > 6) {
          heatsheet.push(newEvent);
          laneNo = 1;
          newEvent = { ...eventInit, [N_EVENT_NAME]: "" };
        }

        let seed = entry[N_CREW].replace(/^.*( [A-Z])$/, "$1");
        if (seed === entry[N_CREW]) {
          seed = ""; // none found
        }
        const crew = fullname
          ? entry[N_CREW].replace(/;/g, " ")
          : `${entry[N_CREW_ABBREV]}`;
        const stroke = showStroke ? ` ${entry[N_STROKE]}` : "";
        const bow = entry[N_BOW];
        if (bow === "4B") {
          console.log(JSON.stringify(entry));
        }
        let hide = false;
        hide = Boolean(
          simplify &&
            crewFilter &&
            !entry[N_CREW].toLowerCase().includes(crewFilterLower)
        );
        hide ||= Boolean(
          simplify &&
            strokeFilter &&
            !entry[N_STROKE].toLowerCase().includes(strokeFilterLower)
        );
        if (!hide) {
          const crewText = (crewFilter || strokeFilter) && simplify ? "" : crew;
          const lane = `Lane ${laneNo++}`;
          colsUsed.add(lane);
          newEvent[lane] = hide ? "" : `${bow} ${crewText}${stroke}`;
        }
      });
      heatsheet.push(newEvent);
    }
  });
  const sortAlphaNum = (a: string, b: string) =>
    a.localeCompare(b, "en", { numeric: true });
  // Prune cols to export of Day and Start if unused
  let colsToExport = [
    N_DAY,
    N_START,
    N_EVENTNUM,
    N_EVENT_NAME,
    ...Array.from(colsUsed).sort(sortAlphaNum),
  ].filter(
    (key) => !((noDays && key === "Day") || (noStarts && key === "Start"))
  );

  // const csv: string[][] = hasSprint ? [colsToExport] : [[N_EVENTNUM, N_START, N_EVENT_NAME]];
  const csv: string[][] = [];
  heatsheet.forEach((event) => {
    csv.push(colsToExport.map((col) => event[col] || ""));
  });

  csv.forEach((row) => {
    while (row.length < colsToExport.length) {
      row.push("");
    }
  });

  const legend: string[][] = [];
  let legendHeader: string[] = [];
  if (!fullname && Object.keys(crewAbbrevs).length > 0) {
    // Add a two column legend for crew abbrev
    // csv.push(['\u00A0']); // &nbsp; equivalent
    legendHeader = [
      "",
      "Abbrev",
      "Name",
      "",
      "Abbrev",
      "Name",
      "",
      "Abbrev",
      "Name",
    ].map((s) => t(s));
    const crews = Object.keys(crewAbbrevs).sort(sortAlphaNum);
    const crewlegends = crews.map((name) => [name, crewAbbrevs[name]]);
    const third = Math.floor((crewlegends.length + 2) / 3);
    for (let i = 0; i < third; i++) {
      legend.push([
        "",
        ...crewlegends[i],
        "",
        ...(crewlegends[third + i] || []),
        "",
        ...(crewlegends[2 * third + i] || []),
      ]);
    }
  }

  if (hasHead && !hasSprint) {
    // Dont' use Lane X column titles for  strictly head race
    colsToExport = colsToExport.map((name) =>
      name.startsWith("Lane") ? "" : name
    );
  }

  return (
    <Stack
      alignItems="center"
      className={avoidBreak ? "AvoidPageBreaks" : undefined}
    >
      <Stack>
        <TableContainer sx={{ width: "auto" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={colsToExport.length}>
                  {!!crewFilter && (
                    <Typography variant="h6">{`${t(
                      "Crew"
                    )}: ${crewFilter}`}</Typography>
                  )}
                  {!!strokeFilter && (
                    <Typography variant="h6">{`${t(
                      "Stroke"
                    )}: ${strokeFilter}`}</Typography>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                {colsToExport.map((col, i) => (
                  <StyledTableHeaderCell key={i} sx={styles[col]}>
                    {col.startsWith("Lane")
                      ? ""
                      : getTitle(HeaderText[col], columnTitles, t) ||
                        getTitle(col, columnTitles, t)}
                  </StyledTableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {csv.map((row, rowNum) => (
                <StyledTableRow key={rowNum}>
                  {row.map((col, i) => (
                    <StyledTableCell key={i} sx={styles[colsToExport[i]]}>
                      {col}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!fullname && !simplify && (
          <TableContainer sx={{ width: "auto", marginTop: "1em" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {legendHeader.map((col, i) => (
                    <StyledTableHeaderCell key={i} sx={styles[col]}>
                      {getTitle(HeaderText[col], columnTitles, t) ||
                        getTitle(col, columnTitles, t)}
                    </StyledTableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {legend.map((row, rowNum) => (
                  <StyledTableRow key={rowNum}>
                    {row.map((col, i) => (
                      <StyledTableCell key={i} sx={styles[colsToExport[i]]}>
                        {col}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      {filteredEventNums.length === 0 && filterActive && (
        <div>{t("No Entries match filter")}</div>
      )}
      <Typography
        sx={{ marginTop: "1em" }}
      >{`Generated ${new Date().toLocaleString()}`}</Typography>
      {singlePagePerClub && <Box className="page-break" />}
    </Stack>
  );
};

export const HeatSheet = () => {
  const [crewFilter] = useCrewFilter();
  const [strokeFilter] = useStrokeFilter();
  const [fullname, setFullname] = useState(true);
  const [showStroke, setShowStroke] = useState(false);
  const [showAllProp, setShowAllProp] = useState(false);
  const [filteredEventNums] = useFilteredEventNums();
  const { t } = useTranslation();

  const [regattaInfo] = useRegattaInfo();
  const columnTitles = regattaInfo?.Titles || {};

  const onFullnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullname(event.target.checked);
  };

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <EventFilter />
      <Stack alignItems="center" className="noprint">
        <Stack direction="row">
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={fullname}
                onChange={onFullnameChange}
                name="fullname"
                color="primary"
              />
            }
            label={t("Full Names")}
          />
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={showStroke}
                onChange={(event) => setShowStroke(event.target.checked)}
                name="stroke"
                color="primary"
              />
            }
            label={`${getTitle("Stroke", columnTitles, t)}`}
          />
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                disabled={Boolean(!crewFilter && !strokeFilter)}
                checked={showAllProp}
                onChange={(event) => setShowAllProp(event.target.checked)}
                name="others"
                color="primary"
              />
            }
            label={t(`All Crews`)}
          />
        </Stack>
      </Stack>
      <HeatSheetDetail
        crewFilter={crewFilter}
        strokeFilter={strokeFilter}
        fullname={fullname}
        showStroke={showStroke}
        showAllProp={showAllProp}
        filteredEventNums={filteredEventNums}
      />
    </Box>
  );
};

export const RegistrationPacket = () => {
  const [showStroke, setShowStroke] = useState(false);
  const [onePagePer, setOnePagePer] = useState(false);
  const [eventSummaryList] = useEventSummaryList();

  const [dayFilter] = useDayFilter();
  const { t } = useTranslation();

  const [regattaInfo] = useRegattaInfo();
  const columnTitles = regattaInfo?.Titles || {};

  let crews = new Set<string>();
  eventSummaryList.forEach((event) => {
    crews = new Set([...crews, ...event.crews]);
  });

  const crewList = Array.from(crews).sort();

  const crewPages = crewList.map((crewFilter, index) => {
    const seededPickList: string[] = [];
    eventSummaryList.forEach((row) => {
      const eventNum = row.EventNum;
      if (dayFilter && row.Day !== dayFilter) {
        return;
      }

      let match = false;
      const filterByLower = crewFilter.toLowerCase();
      (row.crews || []).forEach((crew) => {
        crew = crew.toLocaleLowerCase();
        if (
          crew === filterByLower ||
          (crew.includes(";") && crew.includes(filterByLower))
        ) {
          match = true;
        }
      });
      if (match) {
        seededPickList.push(eventNum);
      }
    });
    return (
      <HeatSheetDetail
        avoidBreak={index !== 0}
        crewFilter={crewFilter}
        strokeFilter={""}
        fullname={false}
        showStroke={showStroke}
        showAllProp={false}
        filteredEventNums={seededPickList}
        singlePagePerClub={onePagePer}
      />
    );
  });

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Stack alignItems="center">
        <Stack direction="row" className="noprint">
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={showStroke}
                onChange={(event) => setShowStroke(event.target.checked)}
                name="stroke"
                color="primary"
              />
            }
            label={`Include ${getTitle("Stroke", columnTitles, t)}`}
          />
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch
                checked={onePagePer}
                onChange={(event) => setOnePagePer(event.target.checked)}
                name="onepage"
                color="primary"
              />
            }
            label={`${getTitle("Print one page per club", columnTitles, t)}`}
          />
        </Stack>
      </Stack>
      {crewPages}
    </Box>
  );
};
